


























import Vue from 'vue'
import Component from 'vue-class-component'
import firebase from 'firebase'

@Component({})
export default class Login extends Vue {
  public form = {
    valid: false,
    error: '',
    rules: {
      email: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+/.test(v) || 'E-mail must be valid'
      ]
    },
    data: {
      email: '',
      password: ''
    }
  }

  public valid() {
    return ( this.$refs.form as Vue & { validate: () => boolean } ).validate()
  }

  public async reset() {
    if (!this.valid()) {
      return
    }

    const auth = ( await this.$firebase ).auth()
    try {
      await auth.sendPasswordResetEmail(this.form.data.email, { url: window.location.href, handleCodeInApp: false })
      await this.$router.push('/password-reset')
    }
    catch (e) {
      this.form.error = 'Invalid email'
    }
  }

  public async login() {
    if (!this.valid()) {
      return
    }

    const auth = ( await this.$firebase ).auth()
    try {
      await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
      await auth.signInWithEmailAndPassword(this.form.data.email, this.form.data.password)
      await this.$router.push('/')
    }
    catch (e) {
      this.form.error = 'Invalid credentials'
    }
  }
}
