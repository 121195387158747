



















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class VueComponent extends Vue {
  async mounted() {
    setTimeout(() => this.$router.push('/login'), 10000)
  }
}
