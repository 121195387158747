


















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class VueComponent extends Vue {
  async mounted() {
    const firebase = await this.$firebase
    await firebase.auth().signOut()
  }
}
